@use 'sass:map';
@use '~@angular/material' as mat;

@mixin theme-reset($theme) {
    $color-config:  mat.get-color-config($theme);
    $primary:       map.get($color-config, 'primary');
    $accent:        map.get($color-config, 'accent');
    $warn:          map.get($color-config, 'warn'); 
    $foreground:    map.get($color-config, 'foreground');
    $background:    map.get($color-config, 'background');

    .bg-primary { 
        background: mat.get-color-from-palette($primary);
        color: mat.get-color-from-palette($primary, default-contrast);
    }
    .bg-accent {
        background: mat.get-color-from-palette($accent);
        color: mat.get-color-from-palette($accent, default-contrast);
    }
    .bg-warn {
        background: mat.get-color-from-palette($warn);
        color: mat.get-color-from-palette($warn, default-contrast);
    }
    .bg-light{ 
        background: mat.get-color-from-palette($background, background); 
    } 

    .text-muted {
        color: mat.get-color-from-palette($foreground, secondary-text) !important;
    }
    .primary-color{
        color: mat.get-color-from-palette($primary);
    }
    .accent-color{
        color: mat.get-color-from-palette($accent);
    }
    .warn-color{
        color: mat.get-color-from-palette($warn);
    }
    .secondary-color{
        color: mat.get-color-from-palette($primary, 100);
    }
    .v-divider{
        background-color: mat.get-color-from-palette($foreground, divider);
    }
    .divider{
        border-top: 1px solid mat.get-color-from-palette($foreground, divider);
    }
      
    .ps__thumb-y{
        background-color: mat.get-color-from-palette($primary, 900);
    } 
    .active-link{
        background-color: mat.get-color-from-palette($primary); 
        color: #fff;
    } 
       
    
    .mat-snack-bar-container.success {
        background: #388E3C;
        color: #fff; 
        .mat-simple-snackbar-action button{
            color: #fff;  
        }
    }
    .mat-snack-bar-container.error {
        background: #E53935;
        color: #fff; 
        .mat-simple-snackbar-action button{
            color: #fff;  
        }
    }

    .bordered{
        border: 1px solid mat.get-color-from-palette($foreground, divider);
    }
    .border-right{
        border-right: 1px solid mat.get-color-from-palette($foreground, divider);
    }
      
    .amet-table{
        thead tr{
            background: mat.get-color-from-palette($primary);
            color: mat.get-color-from-palette($primary, default-contrast);
        } 
        .mat-sort-header-arrow{
            color: mat.get-color-from-palette($background, card);
        }
        thead tr th,
        tbody tr td{
            border-color: mat.get-color-from-palette($foreground, divider);
        }
        &.accent{
            thead tr{ 
                background: mat.get-color-from-palette($accent);
                color: mat.get-color-from-palette($accent, default-contrast);
            } 
        }
    }

    .amet-dialog{  
        &.bg-light{
            .mat-dialog-container{ 
                background: mat.get-color-from-palette($background, background);
            } 
        } 
    }


    input:-webkit-autofill,
    input:-webkit-autofill:hover, 
    input:-webkit-autofill:focus, 
    input:-webkit-autofill:active  {
        transition: background-color 5000s;
        -webkit-text-fill-color: mat.get-color-from-palette($foreground, base) !important;
    }
 

    .translation-panel.mat-expansion-panel {
        background: mat.get-color-from-palette($background, app-bar); 
        &.mat-expanded{
            box-shadow: none;
            border: 1px solid mat.get-color-from-palette($foreground, secondary-text);
        }
        // &::before { 
        //     font-family: "Material Icons";
        //     content: "warning";
        //     font-size: 48px;
        //     line-height: 48px;
        //     color: #f44336; 
        //     margin-bottom: 8px;
        // }
    }

    // .admin-container{
    //     .mat-drawer{
    //         background: mat.get-color-from-palette($background, app-bar);
    //     } 
    // }



    // admin styles
    .breadcrumb{
        a{
            color: mat.get-color-from-palette($primary);
        }
        .breadcrumb-item+.breadcrumb-item:before{
            color: mat.get-color-from-palette($foreground, text);
        }
    } 

    .menu-item .mat-button:not(.active-link) .menu-icon{
        color: mat.get-color-from-palette($primary);
    }

    .quantity-counter{ 
        input[type=text]{ 
            border: 1px solid mat.get-color-from-palette($foreground, divider);
        } 
        button{  
            background: mat.get-color-from-palette($foreground, divider); //mat.get-color-from-palette($background, app-bar); 
            border: 1px solid mat.get-color-from-palette($foreground, divider);
        } 
    }

    .mat-tooltip{
        background-color: rgba(0,0,0, 0.6);
    }

}