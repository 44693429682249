@import "variables";

html, body{
    height: 100%;
}
body {
    font-family: $font-family;
    font-size: 14px;
    line-height: 1.5;
    // overflow-x: hidden;
}
*{
    margin: 0;
    padding: 0;
}
a{
    outline: none;
    text-decoration: none;
}

.w-100{
    width: 100%;
}
.mw-100{
    max-width: 100%;
}
.h-100{
    height: 100% !important;
}
.pointer{
    cursor: pointer;
}
.fw-300{
    font-weight: 300;
}
.fw-400{
    font-weight: 400 !important;
}
.fw-500{
    font-weight: 500;
}
.text-center{
    text-align: center !important;
}
.text-left{
    text-align: left !important;
}
.text-right{
    text-align: right !important;
}
.o-hidden{
    overflow: hidden;
}
.flex-wrapper{
    margin: -8px;
}
.flex-wrapper-x{
    margin: 0 -8px;
}
.flex-wrapper-y{
    margin: -8px 0;
}
.p-relative{
    position: relative;
}
.shadow-none{
    box-shadow: none !important;
}
.bb{
    box-sizing: border-box;
}
.mh-100{
    min-height: 100%;
}
.m-auto{
    margin: 0 auto !important;
}

.default-bg{
    background-image: url("~assets/img/bg.png") !important;     
    background-blend-mode: overlay;
    background-size: 200px !important; 
}

.underline {
    text-decoration-line: underline;
}

// tanslation-panel
.translation-btn.mat-icon-button{
    position: absolute; 
    top: 0; 
    right: 0;
}
.translation-panel-arrow{
    position: absolute;
    right: 8px;
    top: 40px;
    margin-left: -12px;
}
.flex-wrapper-x{
    .translation-btn{ 
        right: 8px;
    }
    .translation-panel-arrow{
        right: 16px;
    }
}


// Material Design override 
.mat-icon-button .mat-icon.mat-icon-xs,
.mat-icon.mat-icon-xs{
    font-size: 14px;
    line-height: 14px;
    height: 14px;
    width: 14px;
    vertical-align: middle;
}
.mat-icon-button .mat-icon.mat-icon-sm,
.mat-icon.mat-icon-sm,
.icon-sm {
    font-size: 18px;
    line-height: 18px;
    height: 18px;
    width: 18px;
    vertical-align: middle;
}
.mat-icon-button .mat-icon.mat-icon-lg,
.mat-icon.mat-icon-lg{
    font-size: 36px;
    line-height: 36px;
    height: 36px;
    width: 36px;
    vertical-align: middle;
}
.mat-icon-button .mat-icon.mat-icon-xlg,
.mat-icon.mat-icon-xlg{
    font-size: 48px;
    line-height: 48px;
    height: 48px;
    width: 48px;
    vertical-align: middle;
}

.mat-progress-spinner.sm,
.mat-progress-spinner.sm svg{
    width: 32px !important;
    height: 32px !important; 
}

// mat button
.mat-raised-button{
    height: 34px !important;
    line-height: 32px !important;
}

//Mat table
.amet-table.mat-table{
    width: 100%;
    .mat-header-cell{
        font-size: 13px;
        color: inherit;
    }
    tr.mat-header-row{
        height: 42px;
    }
    tr.mat-row, tr.mat-footer-row{
        height: 42px;
    }
    th.mat-header-cell:last-of-type, 
    td.mat-cell:last-of-type, 
    td.mat-footer-cell:last-of-type{
        width: 82px;
        text-align: center;
    }
    th.mat-header-cell:first-of-type, 
    td.mat-cell:first-of-type, 
    td.mat-footer-cell:first-of-type{
        padding: 0 16px;
        border-left: 0;
    }
    th.mat-header-cell, 
    td.mat-cell, 
    td.mat-footer-cell{
        padding: 0 16px;
        border-left-width: 1px;
        border-left-style: solid;
    }
    tr:last-child td{
        border-bottom: 0;
    }
}

.amet-table-wrapper{
    height: calc(100vh - 200px);
    &.part{  
        height: calc((100vh - 168px) / 2 );
    }
}
.amet-table{
    width: 100%;
    border-spacing: 0;
    thead tr,
    tbody tr{
        height: 42px;
    }
    thead tr th,
    tbody tr td{
        padding: 0 16px;
        border-bottom-width: 1px;
        border-bottom-style: solid;
        border-right-width: 1px;
        border-right-style: solid;
        &:last-child{
            width: 82px;
            text-align: center;
            border-right: none;
            white-space: nowrap;
        } 
    }
    thead tr th{
        text-align: left;
        font-size: 13px; 
        font-weight: 500;
    } 
    td.row-n{
        width: 30px;
    }
    td.detail-icon{
        width: 42px;
        padding: 0;
    }
    tr.details{
        background-color: rgba(0, 0, 0, 0.03);
        > td{
            padding: 16px;
        }
        table{
            position: relative;
            z-index: 0;
            background: #fff;
        }
    }
    &.sticky-header{
        thead tr th { 
            position: sticky;
            top: 0px;
            z-index: 100;
            background: inherit;
        }
    } 
}

.spinner-wrapper{
    position: absolute;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    place-content: center;
    width: 100%; 
    background: #fff;
    z-index: 999; 
}

// Mat-field
.mat-option-text,
.mat-form-field-label,
.mat-checkbox-label{
    font-weight: 500 !important; 
}
.mat-form-field.sm{
    .mat-form-field-flex{
        align-items: center;
    }
    .mat-form-field-flex > .mat-form-field-infix { 
        padding: 0.5em 0px !important;
        font-size: 13px;
    }
    .mat-form-field-label-wrapper { 
        top: -1.4em; 
    } 
    &.mat-form-field-appearance-outline{
        &.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
            transform: translateY(-1.1em) scale(.75) !important; 
        } 
        .mat-form-field-prefix,.mat-form-field-suffix {
            top: .2em;
        } 
        .mat-form-field-suffix {
            right: -8px;
        } 
        .mat-input-element,
        .mat-select-value{
            position: relative;
            top: -3px;
            line-height: 1;
            font-weight: 500;
        }
    } 
    &.two-suffix{
        .mat-form-field-suffix{
            display: flex;
        }
    } 
    &.pb-0{
        .mat-form-field-wrapper{
            padding-bottom: 0px;
        }
    }
    &.pb-1{
        .mat-form-field-wrapper{
            padding-bottom: 4px;
        }
    }
} 
.mat-form-field.sm.lowyer-suffix .mat-form-field-suffix{
    right: 0;
    font-weight: 500; 
}
.mat-form-field.pb-0{ 
    .mat-form-field-label-wrapper { 
        padding-bottom: 0; 
    }  
}



.mat-autocomplete-panel.custom-autocomplete{
    .mat-option{
        line-height: 32px;
        height: 32px;
        font-size: 13px;
    }
}



.admin-toolbar.mat-toolbar{
    position: fixed;
    top: 0;
    z-index: 9;
    height: $toolbar-height;
    // background-color: #fff !important;
    .logo{ 
        display: flex;
        width: 128px; 
        margin-right: calc(#{$sidenav-width} - (128px + 24px));
        img{
            width: 100%;
            filter: drop-shadow(1px 1px 0px #feffff);
        }
    }
    .fullname{
        font-size: 14px;
        font-weight: 400;
    }
}
 
.admin-container{
    .mat-drawer{
        width: $sidenav-width;
        padding-top: $toolbar-height;
        position: fixed;
        border: none !important;
    }
    .mat-drawer-content{
        min-height: 100vh;
        padding: calc(#{$toolbar-height} + 16px) 16px 16px 16px;
        box-sizing: border-box;
    }
} 



/******* Theme dialog *******/ 
.amet-dialog{ 
    border-radius: 4px; 
    .header{  
        margin: -24px -24px 0px -24px;
        padding: 2px 8px 2px 24px;
    } 
    .mat-dialog-container{  
        .mat-dialog-content{ 
           max-height: 80vh;
        }
    } 
    &.fullscreen{
        width: 100%;
        height: 100%;
        max-width: 100% !important; 
        transform: none !important;
        .mat-dialog-container{ 
            overflow: hidden;
            .mat-dialog-content{
                max-height: calc(100vh - 56px);  
            }
        }       
    } 
} 


.amet-accordion.mat-accordion{ 
    .mat-expansion-panel-header.mat-expanded{
        background: rgba(0, 0, 0, 0.04); 
    } 
    .mat-expansion-panel{
        .mat-expansion-panel-body{
            padding-top: 16px;
            font-size: 13px;
        }
        &.pb-0{
            .mat-expansion-panel-body{
                padding-bottom: 0; 
            } 
        }
        &.pt-0{
            .mat-expansion-panel-body{
                padding-top: 0; 
            } 
        }
        &.has-grid{
            .mat-expansion-panel-body{
                padding: 0 !important;
            }
        }
    } 
} 



// Search-select
.mat-select-panel{
    max-height: 352px !important;
}
.mat-select-panel.search-dropdown{
    overflow: hidden;
    padding-top: 36px;
    .search-input{
        padding: 8px 16px !important;
        box-sizing: border-box;
        position: fixed;
        top: 0;
    }
    .search-container{
        max-height: 256px;
        overflow: auto;
    }
}



.details{
    p span:first-child{
        min-width: 130px;
        display: inline-block;
        font-weight: 500;
    }
}


/******* Responsive *******/ 
// xs
@media screen and (max-width: 599px) {
    
} 

// sm
@media screen and (min-width: 600px) and (max-width: 959px) {
    
}

@media screen and (max-width: 959px) {
     
}

// md
@media screen and (min-width: 960px) and (max-width: 1279px) { }

// lg
@media screen and (min-width: 1280px) and (max-width: 1919px) { }

// xlg
@media screen and (min-width: 1920px) and (max-width: 5000px) { }



::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}
::-webkit-scrollbar-button {
    width: 0px;
    height: 0px;
}
::-webkit-scrollbar-thumb {
    background: #e1e1e1;
    border: 0px none #ffffff;
    border-radius: 0px;
    &:hover{
        background: #cccccc;
    }
    &:active{
        background: #888888; 
    }
}
::-webkit-scrollbar-track {
    background: #666666;
    border: 0px none #ffffff;
    border-radius: 0px;
    &:hover{
        background: #666666;
    }
    &:active{
        background: #333333; 
    }
}
::-webkit-scrollbar-corner {
    background: transparent;
}

/* perfect-scrollbar */
.ps {
    -ms-touch-action: auto;
    touch-action: auto;
    overflow: hidden !important;
    -ms-overflow-style: none; 
    position: relative;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .ps {
        overflow: auto !important; 
    } 
}
.ps__rail-x {
    display: none;
    opacity: 0;
    transition: background-color .2s linear, opacity .2s linear;
    -webkit-transition: background-color .2s linear, opacity .2s linear;
    height: 9px;
    bottom: 0px;
    position: absolute;
}

.ps__rail-y {
    display: none;
    opacity: 0;
    transition: background-color .2s linear, opacity .2s linear;
    -webkit-transition: background-color .2s linear, opacity .2s linear;
    width: 9px;
    right: 0px;
    left: auto !important;
    position: absolute;
    z-index: 998;
}

.ps--active-x > .ps__rail-x,
.ps--active-y > .ps__rail-y {
    display: block;
    background-color: transparent;
}

.ps:hover > .ps__rail-x,
.ps:hover > .ps__rail-y,
.ps--focus > .ps__rail-x,
.ps--focus > .ps__rail-y,
.ps--scrolling-x > .ps__rail-x,
.ps--scrolling-y > .ps__rail-y {
    opacity: 0.6;
}

.ps__rail-x:hover,
.ps__rail-y:hover,
.ps__rail-x:focus,
.ps__rail-y:focus {
    background-color: #eee;
    opacity: 0.9;
}

.ps__thumb-x {
    background-color: #aaa;
    border-radius: 6px;
    transition: background-color .2s linear, height .2s ease-in-out;
    -webkit-transition: background-color .2s linear, height .2s ease-in-out;
    height: 4px;
    bottom: 1px;
    position: absolute;
}

.ps__thumb-y {
    background-color: #aaa;
    border-radius: 6px;
    transition: background-color .2s linear, width .2s ease-in-out;
    -webkit-transition: background-color .2s linear, width .2s ease-in-out;
    width: 4px;
    right: 1px;
    position: absolute;
    z-index: 999;
}

.ps__rail-x:hover > .ps__thumb-x,
.ps__rail-x:focus > .ps__thumb-x {
    background-color: #999;
    height: 7px;
}

.ps__rail-y:hover > .ps__thumb-y,
.ps__rail-y:focus > .ps__thumb-y {
    background-color: #999;
    width: 7px;
}

@supports (-ms-overflow-style: none) {
    .ps {
        overflow: auto !important;
    }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .ps {
        overflow: auto !important;
    }
} 